'use client';

import { IConsultation } from '@sapientpro/sapientpro-data-models';
import { IFreeConsultationMailRequestBody } from '@sapientpro/sapientpro-data-models/src/contact-form/contact-form';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import client from '../../../apiClient';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Img from '../../../components/Img';
import { theme } from '../../../store/theme';
import useDebouncedCallback from '../../../useDebounceCallback';
import styles from './Consultation.module.scss';
import GradientCircles from '../../../components/GradientCircles';
import { GAEmit, GAEvents } from '../../../gtagHelper';

type Errors = {
  email?: string,
};

enum SubmitStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum SubmitType {
  NEWSLETTER = 'newsletter',
}

type ConsultationProps = {
  data: IConsultation,
  className?: string,
  homepageVersion?: boolean,
  formId?: string,
  id?: string,
  type?: SubmitType
};

type SubscribeResponse = {
  message: string;
};

const Consultation = ({
  data, className, homepageVersion, formId, id, type,
}: ConsultationProps) => {
  const router = useRouter();
  const appTheme = useAtomValue(theme);
  const [inputValue, setInputValue] = useState<string>('');
  const [isFormMutated, setIsFormMutated] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);
  const [customServerError, setCustomServerError] = useState<string | null>(null);

  const validateForm = () => {
    setErrors({});
    let currentErrors: Errors = {};

    if (inputValue !== '' && !/\S+@\S+\.\S+/.test(inputValue)) {
      currentErrors.email = 'Please enter a valid email';
    } else if (inputValue === '') {
      currentErrors.email = 'Please provide your email address';
    }
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const validateMutatedForm = useDebouncedCallback(() => {
    validateForm();
  }, 300);

  useEffect(() => {
    if (isFormMutated) {
      validateMutatedForm();
    }
  }, [inputValue, isFormMutated]);

  const submitForm = async (formData: IFreeConsultationMailRequestBody) => {
    setIsLoading(true);
    setSubmitStatus(null);
    setCustomServerError(null);
    try {
      if (type === SubmitType.NEWSLETTER) {
        const subscribe = await client.getResponse<SubscribeResponse>(`news-subscription/subscribe?email=${formData.email}&us=false`);
        if (subscribe.status === 304) {
          setCustomServerError('You are already subscribed to our newsletter');
          setSubmitStatus(SubmitStatus.ERROR);
        } else if (subscribe.status === 429) {
          const { message } = subscribe.response;
          setCustomServerError(message);
          setSubmitStatus(SubmitStatus.ERROR);
        } else if (subscribe.status === 200) {
          setSubmitStatus(SubmitStatus.SUCCESS);
          router.push('/subscribed');
        } else {
          setSubmitStatus(SubmitStatus.ERROR);
        }
      } else {
        const subscribe = await client.postResponse<SubscribeResponse>('contact-form-lead/consultation', {
          cache: 'no-cache',
          body: JSON.stringify(formData),
        });
        if (subscribe.status === 429) {
          const { message } = subscribe.response;
          setCustomServerError(message);
          setSubmitStatus(SubmitStatus.ERROR);
        } else if (subscribe.status === 200) {
          setSubmitStatus(SubmitStatus.SUCCESS);
        } else {
          setSubmitStatus(SubmitStatus.ERROR);
        }
      }
    } catch (e) {
      console.error('subscribe error', e);
      setSubmitStatus(SubmitStatus.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsFormMutated(true);
    const isFormValid = validateForm();
    GAEmit(GAEvents.consultationFormSubmission);

    isFormValid && submitForm({
      email: inputValue,
    });
  };

  return (
    <section
      className={classnames(styles.consultation, className)}
      id={id}
    >
      <div className='container'>
        <div className={classnames(styles.content, {
          [styles.content_homepage]: homepageVersion,
        })}
        >
          <h2 className={classnames('sectionTitle', styles.title)}>
            <AnimatedTitle title={data?.title} />
          </h2>
          <p className={styles.text}>
            {data?.description}
          </p>
          <form
            className={classnames(styles.form, className)}
            id={formId}
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div
              className={styles.inputWrap}
            >
              <input
                type='email'
                placeholder='Enter your email'
                className={styles.input}
                value={inputValue}
                onChange={e => {
                  setSubmitStatus(null);
                  setInputValue(e.target.value);
                }}
              />
            </div>
            <button
              type='submit'
              className={styles.submitButton}
              aria-label='Get consultation'
              disabled={isLoading || !!errors?.email}
            >
              {isLoading ? (
                <img
                  className={styles.spinner}
                  src={`/media/spinner_${appTheme}.webp`}
                  alt='spinner'
                />
              ) : (
                <svg>
                  <use
                    xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                    href='/media/arrow-right-bold.svg#arrowRight'
                  />
                </svg>
              )}
            </button>
            <div className={styles.line}>
              <Img
                src={`/media/consultation/consultationLine_${appTheme}.svg`}
                alt='consultation line'
              />
            </div>
            {(submitStatus || errors.email) && (
              <p className={classnames(styles.status, {
                [styles.error]: submitStatus === SubmitStatus.ERROR || errors.email,
                [styles.success]: submitStatus === SubmitStatus.SUCCESS,
              })}
              >
                {!submitStatus && errors?.email}
                {submitStatus === SubmitStatus.ERROR && (customServerError || 'Form submission error. Try again')}
                {submitStatus === SubmitStatus.SUCCESS && 'The form has been successfully submitted'}
              </p>
            )}
          </form>
        </div>
        <GradientCircles />
      </div>
    </section>
  );
};

export default Consultation;
