import { useEffect, useState } from 'react';

// export const useSectionOffsetScroll = (sectionRef: React.RefObject<HTMLElement>) => {
export const useSectionOffsetScroll = (sectionId: string) => {
  const [sectionOffset, setSectionOffset] = useState<number>(0);

  const getPosition = () => {
    let section = document.getElementById(sectionId);

    if (section) {
      const screenHeight = window.innerHeight;
      const y = section.getBoundingClientRect().top;
      const visibleHeight = screenHeight - y > 0 ? screenHeight - y : 0;
      setSectionOffset(visibleHeight);
    }
  };

  useEffect(() => {
    getPosition();
    window.addEventListener('resize', getPosition);
    return () => window.removeEventListener('resize', getPosition);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', getPosition, { passive: true });

    return () => {
      window.removeEventListener('scroll', getPosition);
    };
  }, []);

  return sectionOffset;
};
