'use client';

import React from 'react';
import { ICategory, IIndustry } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import Link from 'next/link';
import AnimatedTitle, { AnimationType } from './AnimatedTitle';
import styles from './PostFilters.module.scss';

// TODO proper types and maybe split logic of links and buttons version

type PostFiltersProps = {
  className?: string,
  filters?: IIndustry[] | ICategory[],
  clearFilter?: string,
  callback?: (slug: string) => void,
  clearCallback?: () => void,
  activeFilter?: string,
  // props for case page and for usecases when we want filters to redirect on case page with filter preset
  useLinks?: boolean,
  linkPrefix?: {
    clear: string,
    full: string,
  }
  linkPostfix?: string;
};
const PostFilters = ({
  className, filters, clearFilter, callback, clearCallback, activeFilter, useLinks, linkPrefix, linkPostfix,
}: PostFiltersProps) => (
  useLinks
    ? (
      <nav className={classnames(styles.filters, className)}>
        {clearFilter && (
          <Link
            href={linkPrefix?.clear || '/'}
            className={classnames(styles.filter, {
              [styles.active]: activeFilter === undefined,
            })}
            scroll={false}
            replace
          >
            {clearFilter}
          </Link>
        )}
        {filters && filters.map((filter) => (
          <Link
            key={filter.id}
            href={`${linkPrefix?.full}${filter.slug}${linkPostfix || ''}`}
            className={classnames(styles.filter, {
              [styles.active]: activeFilter === filter.slug,
            })}
            scroll={false}
            replace
          >
            <AnimatedTitle
              title={filter.title}
              animationType={AnimationType.NONE}
            />
          </Link>
        ))}
      </nav>
    )
    : (
      <nav className={classnames(styles.filters, className)}>
        {clearFilter && (
          <button
            type='button'
            className={classnames(styles.filter, {
              [styles.active]: activeFilter === undefined,
            })}
            onClick={clearCallback}
          >
            {clearFilter}
          </button>
        )}
        {filters && filters.map((filter) => (
          <button
            key={filter.id}
            type='button'
            className={classnames(styles.filter, {
              [styles.active]: activeFilter === filter.slug,
            })}
            onClick={() => callback?.(filter.slug)}
          >
            <AnimatedTitle
              title={filter.title}
              animationType={AnimationType.NONE}
            />
          </button>
        ))}
      </nav>
    ));

export default PostFilters;
